import { useEffect, useRef, useState } from 'react';

const defaultStopCondition = (counter: number) => counter <= 0;

export const useCountdown = (start: number, interval = 1000, stopCondition = defaultStopCondition) => {
  const [counter, setCounter] = useState(start);
  const countdownRef = useRef<number>();

  useEffect(
    function countdownTimer() {
      countdownRef.current = window.setInterval(() => {
        setCounter((prev) => {
          const newValue = prev - 1;
          if (stopCondition(newValue)) {
            clearInterval(countdownRef.current);
          }

          return newValue;
        });
      }, interval);

      return () => {
        clearInterval(countdownRef.current);
      };
    },
    [interval, stopCondition],
  );

  return counter;
};
